var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-0 h-100"},[_c('h2',[_vm._v("Vérification l'adresse email associée à votre compte")]),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[(_vm.isLoading)?_c('div',[(_vm.isLoading)?_c('p',[_vm._v("Vérification en cours...")]):_vm._e(),_c('p',[_c('b-spinner',{staticClass:"mx-auto d-block",staticStyle:{"width":"3rem","height":"3rem"}})],1)]):_vm._e(),(!_vm.isLoading && _vm.isvalid)?_c('div',{staticClass:"text-center"},[_c('i',{staticClass:"ri-mail-check-line d-block mx-auto font-7rem"}),_c('p',[_vm._v(" Votre adresse mail a été verifiée avec succes ")]),(_vm.canSetPassword)?_c('p',[_vm._v(" Pour définir votre mot de passe, veuillez cliquer ici "),_c('br'),_vm._v(" "),_c('br'),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":function (){
            this$1.$router.push({
              name: 'etablissements-agents-create-password-agent',
              params: {
                email: _vm.email,
              },
            })
          }}},[_vm._v(" Définir mon mot de passe ")])],1):_c('p',[_vm._v(" Vous pouvez dès à présent vous connecter à la plateforme. ")])]):_vm._e(),(!_vm.isLoading && !_vm.isvalid)?_c('div',{staticClass:"text-center"},[_c('i',{staticClass:"ri-mail-close-line d-block mx-auto font-7rem"}),_c('p',[_vm._v(" Une erreur est survenue lors de la validation de votre email : "),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }